import * as React from 'react';

import {
  Hr,
  Bullet,
  Titles,
  TitlesWrapper,
  Content,
  Ul,
  Li,
} from './ContentStyles';

export const Intro = () => {
  return (
    <>
      <Titles md={3}>
        <TitlesWrapper>
          <h2 style={{textDecoration: 'underline'}}>Intro</h2>
        </TitlesWrapper>
        <Hr />
      </Titles>
      <Content md={6}>
        <Ul>
          <Li>
            <Bullet>✔️</Bullet>
            <div>Full-stack Web Developer, Software Engineer</div>
          </Li>
          <Li>
            <Bullet>✔️</Bullet>
            <div>Enthusiastic developer for the last {`${(new Date().getFullYear() - 2011)}+`} years</div>
          </Li>
          <Li>
            <Bullet>✔️</Bullet>
            <div>Part of the IT industry for {`${(new Date().getFullYear() - 2005)}`} years</div>
          </Li>
          <Li>
            <Bullet>✔️</Bullet>
            <div>
              Diverse background of support, applications specialist, infrastructure, system administration & more
            </div>
          </Li>

        </Ul>
      </Content>
    </>
  );
};
