import * as React from 'react';
import styled from 'styled-components';

import {
  Ul,
  Bullet,
  Hr,
  Titles,
  TitlesWrapper,
  Content,
  Li,
} from './ContentStyles';

export const Description = styled.div`
font-size: 15px;
font-weight: 500;
`;
export const Company = styled.div`
font-size: 13px;
font-weight: none;
`;

export const Experience = () => {
  return (
    <>
      <Titles md={3}>
        <TitlesWrapper>
          <h2 style={{textDecoration: 'underline'}}>Experience</h2>
          <h4>Brief Summary</h4>
        </TitlesWrapper>
        <Hr />
      </Titles>
      <Content md={6}>
        <Ul>
          <Li>
            <Bullet>2018 - Now 👉🏻</Bullet>
            <div>
            <Description>GIS Software Engineer & Senior Consultant</Description>
            <Company>ESRI Australia</Company>
            </div>
          </Li>
          <Li>
            <Bullet>2011 - 2018 👉🏻</Bullet>
            <div>
            <Description>
              Dynamics CRM & GIS Developer</Description>
              <Company>Ministry of Environmental Protection, Israel</Company>
              </div>
          </Li>
          <Li>
            <Bullet>2007 - 2011 👉🏻</Bullet>
          <div>
            <Description>
              Enterprise CRM Operator/SpeciaList</Description>
              <Company>Partner Communication, Israel</Company>
              </div>
          </Li>
          <Li>
            <Bullet>2005 - 2007 👉🏻</Bullet>
            <Description>IT support roles</Description>
          </Li>
        </Ul>
      </Content>
    </>
  );
};
