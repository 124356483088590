import * as React from 'react';

import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Hr, Titles, TitlesWrapper, Content } from './ContentStyles';
import { ProjectCard } from './ProjectCard';

import Modal from './modal';
import { ModalContent } from './ModalContent';
import './modalStyle.css';

import { GetAllDBRecords } from './QueryDatabase';

const CardsRow = styled(Row)`
  display: flex;
  width: 100%;
  text-align: right;
`;

export const Projects = () => {
  const createRef = React.useRef();

  const data = GetAllDBRecords();
  data.forEach(function (l, i) {
    if (typeof this[i]['date'] === 'string') {
      let parsedDate = this[i]['date'].split('/');
      let year = parsedDate[2];
      let month = parsedDate[1];
      let day = parsedDate[0];
      this[i]['date'] = new Date(`${year}-${month}-${day}`);
    }
  }, data);
  data.sort((a, b) => b['date'] - a['date']);

  const [selectedItem, setSelectedItem] = React.useState();

  const clickCallback = (e) => {
    setSelectedItem(data.filter((i) => i['id'] === e.target.id)[0]);
    createRef.current.openModal();
  };

  return (
    <>
      <Titles md={3} style={{ alignItems: 'start' }}>
        <TitlesWrapper>
          <h2 style={{textDecoration: 'underline'}}>Projects</h2>
        </TitlesWrapper>
        <Hr />
      </Titles>
      <Content md={6}>
        <CardsRow>
          {data.map((item) => {
            const id = item.id;
            const date = item.date;
            const isActive = item.active;
            const mongodb_id = item.mongodb_id;
            const title = item.title;
            const subtitle = item.subtitle;
            const stack = item.stack;
            const rating = item.rating;
            const image_title = item.image_title;

            return (
              <ProjectCard
                clickHandler={clickCallback}
                key={mongodb_id}
                id={id}
                title={title}
                subtitle={subtitle}
                stack={stack}
                rating={rating}
                image_title={image_title}
                date={date}
                isActive={isActive}
              />
            );
          })}
        </CardsRow>
      </Content>
      <Modal ref={createRef}>
        <ModalContent data={selectedItem} />
      </Modal>
    </>
  );
};
