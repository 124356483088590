import * as React from 'react'

import Layout from '../components/layout'
import { ThemeContext } from '../components/ThemeContext'
import { MainContent } from '../components/MainContent'

import styled from 'styled-components'
import Seo from '../components/seo'

const Footer = styled.div`
  position: fixed;
  bottom: 3px;
  right: 15px;
  z-index: 99;
  color: ${(props) => (props.theme.name === 'light' ? 'black' : 'white')} !important;
  cursor: crosshair;
  text-shadow: ${(props) => (props.theme.name === 'light' ? '1px 1px 15px blue, 1px 1px 3px #fff' : '1px 1px 15px yellow, 1px 1px 3px #000')};
  color:black;
`;

const IndexPage = () =>
  <main>
    <Layout>
      <title>Home Page</title>
      <Seo title="Home" keywords={[`gatsby`, `application`, `react`]} />

      <MainContent />
      <ThemeContext.Consumer>
        {(theme) => (
          <Footer theme={theme}>Shay Lavi &#169; 2021</Footer>
        )}
      </ThemeContext.Consumer>
    </Layout>
  </main>

export default IndexPage;

const gtag = process.env.GATSBY_GTAG
export const Head = () => [<script key='gtag1' async src={"https://www.googletagmanager.com/gtag/js?id=" + gtag}></script>,
<script key='gtag2'>
  {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

gtag('config', '${gtag}');`}
</script>]
